var sortBy = "urgency";

export function setSortBy (newSortBy) {
  sortBy = newSortBy
}

// numeric comparison
function compareNumericBy(a,b,attribute) {
  var aVal = parseFloat($(a).data(attribute));
  var bVal = parseFloat($(b).data(attribute));
  if ( aVal === bVal ) { return 0; }
  return aVal > bVal ? 1 : -1;
}

function compareTruthBy(a,b,attribute) {
  var aVal = $(a).data(attribute);
  var bVal = $(b).data(attribute);
  return aVal && !bVal ? -1 : !aVal && bVal ? 1 : 0; 
}

function compareAlphaBy(a,b,attribute) {
  var aVal = $(a).data(attribute);
  var bVal = $(b).data(attribute);
  return aVal === bVal ? 0 : aVal < bVal ? -1 : +1 
}

// slugs compare in alphabetical order
function compareSlug(a,b) {
  return compareAlphaBy(a,b,'slug')
}

// pledges compare in reverse numeric order
function comparePledge(a,b) {
  return compareNumericBy(b,a,'pledge')
}

// lasttouch compares in reverse timestamp order
function compareLastTouch(a,b) {
  return compareNumericBy(b,a,'lasttouch')
}

// losedate compares in timestamp order
function compareLoseDate(a,b) {
  return compareNumericBy(a,b,'losedate')
}

function compareUrgency(a,b) { 
  return compareAlphaBy(a,b,'urgencykey')
}

export function sortGoals() {
  $("#goals-active .goal").sort(function(a, b) {
    // if a has a PPR already, then it should be sorted first, with fallback to losedate, and then pledge, and then slug
    var comparison = 0;
    if (sortBy === "urgency") {
      comparison = compareUrgency(a, b);
      // comparison = comparePPR(a, b) || compareLoseDate(a, b) || comparePledge(a, b) || compareSlug(a, b);
    }
    else if (sortBy === "slug") {
      comparison = compareSlug(a, b);
    }
    else if (sortBy === "pledge") {
      comparison = comparePledge(a, b) || compareLoseDate(a, b) || compareSlug(a, b);
    } else if (sortBy === "lasttouch") {
      comparison = compareLastTouch(a, b) || compareSlug(a, b);
    }
    return comparison;
  }).appendTo($("#goals-active"));
  $(".dashboard-new-goal").appendTo($("#goals-active"));
  fixTabIndexes();

  $(".dashboard-header-wrapper .sort-arrow").removeClass("octicon-triangle-down");
  $(".dashboard-header-wrapper .sort-arrow[data-sort-string='" + sortBy + "']").addClass("octicon-triangle-down");
};

function fixTabIndexes() {
  $.each($(".goal"), function(i, e) {
    var tabindexBase = 1000 + i*3;
    $(e).find("select[name='date']").attr("tabindex", tabindexBase + 0);
    $(e).find("input[name='datapoint-value']").attr("tabindex", tabindexBase + 1);
    $(e).find("input[name='datapoint-comment']").attr("tabindex", tabindexBase + 2);
    $(e).find("input[type='submit']").attr("tabindex", tabindexBase + 3);
  });
}