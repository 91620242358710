import { setSortBy, sortGoals } from "./sort";

$(document).ready(function() {
  // this ensures that this js only runs if you're on the dashboard or gallery page
  if ($(".dashboard").length === 0 && $(".gallery").length === 0) {
    return;
  }

  var errorTimeouts = {};
  function imageLoaded(img) {
    return img.complete && img.naturalHeight !== 0;
  }

  function getSlug(img) {
    // Gets the slug for a graph image
      if ($(".gallery").length > 0) //on gallery
      {
          return $(img).closest('.gallery-goal').attr("data-slug");
      } else //on dashboard
      {
          return $(img).closest('div.goal').attr("data-slug");
      }
  }

  function onGraphError(event) {
    // Error handler for bad graphs.
    // Schedules a graph refresh shortly in the future in order to reduce chance
    // of slamming the backend.
    var slug = getSlug(event.target);
    clearTimeout(errorTimeouts[slug]);
    errorTimeouts[slug] = setTimeout(refreshGraph, 5000, slug);
  }

  function checkGraphs()
  {
    // checkGraphs() checks all the graph images on the page for bad loads
    // and sets them up to automatically fix themselves for future loads
    var graphImages = $("img.mini, img.large, .gallery-goal img");
    graphImages.on("error", onGraphError);

    // It probably already loaded, though, so if it loaded wrong on original load,
    // the error is already gone and won't be caught by that error handler.

    // Handle any currently broken images.
    graphImages.each(function( index ) {
        if (!imageLoaded(this))
        {
            refreshGraph(getSlug(this));
        }
    });

  }

  function refreshGraph(slug, flash) {
      //This is a quiet version of refreshGoal.  It is used by checkGraphs.

      beetils.pollUntilBeebrainUpdates("me", slug, function(data) {
          var goalDiv$ = $(".goal[data-slug='" + slug + "']");
          goalDiv$.find("textarea[name='datapoint-comment']").val("");
          goalDiv$.find("textarea[name='datapoint-comment']").attr("placeholder", "");
          goalDiv$.find(".doom").data("doom", data.losedate);
          goalDiv$.find(".baremin").data("baremin", data.baremin);
          goalDiv$.find(".baremin").data("baremintotal", data.baremintotal);
          goalDiv$.data("losedate", data.losedate);
          goalDiv$.data("urgencykey", data.urgencykey);
          goalDiv$.data("lasttouch", moment(data.lasttouch).unix());
          goalDiv$.data("baremin", data.baremin);
          goalDiv$.removeClass("blue orange red green dkgreen gray");
          goalDiv$.addClass(beetils.doomColorClass(data.losedate, data.coasting));
          if ($.trim(goalDiv$.find(".last-datapoint").text()) != data.last_datapoint.canonical) {
            goalDiv$.find(".todayta").show();
          }
        // the canonical datapoint has been sanitized back in rails, so it 
        // is safe to set this html with the canonical datapoint, even though 
        // it is technically user input text
          goalDiv$.find(".last-datapoint").html(data.last_datapoint.canonical);

          var img$ = goalDiv$.find(".thumbnail img");
          // this is pretty hacky, but it seems like the graph generation lags
          // slightly behind, so we ask for it one second after we get the all
          // clear that the goal is updated.
          setTimeout(function() {
            beetils.hideInfinibee(slug);
            img$.prop("src", data.thumb_url + "?t=" + (new Date()).getTime());
          }, 1000);
          updateBaremin(slug);
      }, function() {
          beetils.hideInfinibee(slug);
      });
  };


  setTimeout(function(){ checkGraphs() }, 5000);  //start looking at graphs 5 seconds after the javascript loads

  var expandCollapseDuration = 175;

  // expand a single goal; 
  // goal$ is a jquery object
  function expandGoal(goal$, refresh) {
    var gslug = ""+goal$.data("slug"); //cast numeric slugs to string
    var octicon = goal$.find(".expanded-toggle .mega-octicon");
    octicon.removeClass("octicon-chevron-right");
    octicon.addClass("octicon-chevron-down");

    goal$.find(".infinibee-parent .thumbnail img").switchClass("mini", "large", expandCollapseDuration);

    var expandedList = localStorage.getItem("com.beeminder.dashboard.expandedList") || "";
    var expandedArray = $.unique(expandedList.split(",")).filter(function(e){ return e.length > 0; });
    if (expandedArray.indexOf(gslug) === -1) {
      expandedArray.push(gslug);
      expandedList = expandedArray.join();
    }
    if (expandedArray.length === $(".goal").length) {
      expandHeader();
    }
    localStorage.setItem("com.beeminder.dashboard.expandedList", expandedList);
    if (expandedArray.indexOf(gslug) > -1) {
      goal$.find(".refresh-wrapper, form").fadeIn(expandCollapseDuration);
    }
    // and refresh the graph

    if (refresh) {
      beetils.showInfinibee(gslug);
      refreshGoal(gslug) //refresh goal takes a slug, and not the jquery object
    }
  }

  function expandHeader() {
    var octicon = $(".expand-collapse .mega-octicon");
    octicon.removeClass("octicon-chevron-right");
    octicon.addClass("octicon-chevron-down");
    $(".dashboard-header-wrapper .spacer").switchClass("", "expanded", expandCollapseDuration);
    localStorage.setItem("com.beeminder.dashboard.expanded", true);
  }

  function collapseHeader() {
    var octicon = $(".expand-collapse .mega-octicon");
    octicon.addClass("octicon-chevron-right");
    octicon.removeClass("octicon-chevron-down");
    $(".dashboard-header-wrapper .spacer").switchClass("expanded", "", expandCollapseDuration);
    localStorage.removeItem("com.beeminder.dashboard.expanded");
  }

  function collapseGoal(goal$) {
    var gslug = ""+goal$.data("slug"); //cast numeric slug to strings
    var octicon = goal$.find(".expanded-toggle .mega-octicon");
    octicon.removeClass("octicon-chevron-down");
    octicon.addClass("octicon-chevron-right");

    goal$.find(".refresh-wrapper, form").hide();

    goal$.find(".infinibee-parent .thumbnail img").switchClass("large", "mini", expandCollapseDuration);

    var expandedList = localStorage.getItem("com.beeminder.dashboard.expandedList");
    if (expandedList) {
      var expandedArray = $.unique(expandedList.split(",")).filter(function(e) { return e.length > 0; });
      var index = expandedArray.indexOf(gslug)
      if (index != -1) {
        expandedArray.splice(index, 1);
        expandedList = expandedArray.join();
      }
      if (expandedArray.length === 0) {
        collapseHeader();
      }
    } else {
      expandedList = "";
    }
    localStorage.setItem("com.beeminder.dashboard.expandedList", expandedList);
  }

  // this is to toggle a single goal
  function toggleGoal(goal$) {
    var octicon = goal$.find(".mega-octicon");
    if (octicon.hasClass("octicon-chevron-right")) {
      expandGoal(goal$, beemApi.beetaFeet.dashboard_toggle_refresh);
    } else {
      collapseGoal(goal$);
    }
  }

  function refreshGoal(slug) {
    beetils.pollUntilBeebrainUpdates("me", slug, function(data) {
      var goalDiv$ = $(".goal[data-slug='" + slug + "']");
      goalDiv$.find("textarea[name='datapoint-comment']").val("");
      goalDiv$.find("textarea[name='datapoint-comment']").attr("placeholder", "");
      goalDiv$.find(".doom").data("doom", data.losedate);
      goalDiv$.find(".baremin").data("baremin", data.baremin);
      goalDiv$.find(".baremin").data("baremintotal", data.baremintotal);
      goalDiv$.data("losedate", data.losedate);
      goalDiv$.data("urgencykey", data.urgencykey);
      goalDiv$.data("lasttouch", moment(data.lasttouch).unix());
      goalDiv$.data("baremin", data.baremin);
      goalDiv$.removeClass("blue orange red green dkgreen gray");
      goalDiv$.addClass(beetils.doomColorClass(data.losedate, data.coasting));
      if ($.trim(goalDiv$.find(".last-datapoint").text()) != data.last_datapoint.canonical) {
        goalDiv$.find(".todayta").show();
      }
      // the canonical datapoint has been sanitized back in rails, so it 
      // is safe to set this html with the canonical datapoint, even though 
      // it is technically user input text
      goalDiv$.find(".last-datapoint").html(data.last_datapoint.canonical);

      var img$ = goalDiv$.find(".thumbnail img");
      // this is pretty hacky, but it seems like the graph generation lags
      // slightly behind, so we ask for it one second after we get the all
      // clear that the goal is updated.
      setTimeout(function() {
        beetils.hideInfinibee(slug);
        img$.prop("src", data.thumb_url + "?t=" + (new Date()).getTime());
      }, 1000);
      beetils.showFlash("success", "Goal updated! Click \"Urgency\" to re-sort.");

      updateBaremin(slug);
    }, function() {
      beetils.hideInfinibee(slug);
      alert("There was a problem refreshing your goal");
    });
  };

  function refreshAllGoals() {
    $.each($(".goal"), function (i, e) {
      refreshGoal($(e).data("slug"));
    });
  };

  sortGoals();

  function updateBaremin(slug) {
    var row = $(".goal[data-slug='" + slug + "']");
    var yaw = row.data("yaw"); 
    var dir = row.data("dir"); 
    var append_delta, append_abs;
    if (yaw === -1 && dir === 1) { // WEEN
      append_delta = ", "
      append_abs = " total, "
    }
    else if (yaw === 1 && dir === -1) { // RASH
      append_delta = ""
      append_abs = " total"
    }
    else if (yaw === -1 && dir === -1) { // PHAT
      append_delta = " today,"
      append_abs = " today,"
    } 
    else if (yaw === 1 && dir === 1) { // MOAR
      append_delta = ""
      append_abs = " total"
    }

    var text;
    if (beetils.bareminFormat(slug) === "delta") {
      text = row.find(".baremin").data("baremin");
      if (typeof(text) === "number") {
        text = parseFloat(text);
        if (text > 0) { text = "+" + text; }
      } else if (typeof(text) === "string" && !text.match(/^\+/)) { 
        // handle when bare min is in HH:MM format
        var hour = parseFloat(text.split(":")[0])
        if (hour >= 0 && text[0] !== "-") { text = "+" + text; }
      }
      text += append_delta;
    } else {
      text = row.find(".baremin").data("baremintotal");
      if (typeof(text) === "number") {
        text = parseFloat(text);
        text = text + append_abs; 
      } else if (typeof(text) === "string" && !text.match(/^\+/)) { 
        // handle when bare min is in HH:MM format
        var hour = parseFloat(text.split(":")[0])
        if (hour >= 0) { text = text + append_abs; }
      } else {
        text = text + append_abs;
      }
    }

    const phat = yaw === -1 && dir === -1;
    if (row.data("limiter")) {
      text = "limit " + text;
    } else if (phat) {
      text = "hard cap " + text;
    }

    row.find(".baremin").text(text);
  }

  // this binds the toggle goal button for each individual goal on the dashboard
  $(".goals .expanded-toggle").click(function(e) {
    toggleGoal($(this).parent().parent());
  });

  // Trying out refresh-on-double-click
  $(".goals .expanded-toggle").dblclick(function(e) {
    // do what Bee did in expandGoal; unDRY warning
    var goal$ = $(this).parent().parent();
    var gslug = ""+goal$.data("slug"); // cast numeric slugs to string
    beetils.showInfinibee(gslug); // why isn't this part of refreshGoal?
    refreshGoal(gslug) // refreshGoal takes a slug, and not the jquery object
  });

  // this binds the expand/collapse all button in the dashboard header
  $(".expand-collapse").click(function(e) {
    var octicon = $(this).find(".mega-octicon");
    if (octicon.hasClass("octicon-chevron-right")) {
      expandHeader();
      $(".goal").each(function(i, e) {
        expandGoal($(e));
      });
    } else {
      collapseHeader();
      $(".goal").each(function(i, e) {
        collapseGoal($(e));
      });
    }
  });

  $(".insta-delete").click(function(e) {
    e.preventDefault();
    if (!confirm("This permanently deletes the goal (and all its data). There's no undo! Are you sure?")) {
      return;
    }
    var slug = $(this).data("slug");
    var params = $.extend(beetils.tokenParams(), {
      "_method": "PUT",
      "instadelete": true,
      "slug": slug
    });
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + $(this).data("slug") + ".json",
      data: params,
      success: function(data) {
        beetils.showFlash("success", "Deleted your goal.");
        $(".gallery-goal[data-slug='" + slug + "']").remove();
      },
      error: function(error) {
        alert("There was a problem deleting your goal");
      }
    });
  });

  if (localStorage.getItem("com.beeminder.dashboard.expanded")) {
    expandHeader();
  }

  // get list from localstorage and expand goals (happens once on page load)
  var list = localStorage.getItem("com.beeminder.dashboard.expandedList")
  if (list && list.length > 0) {
    var expandedArray = list.split(",").filter(function(e) { return e.length > 0; });
    var presentArray = [] 
    expandedArray.forEach(function(e) {
      var goal$ = $(".goal[data-slug='" + e + "']")
      if (goal$.length > 0) {
        // expand this goal, but only if actually present on the page
        expandGoal(goal$);
        // and add it to the present list
        presentArray.push(e);
      } 
    });
    // then update the stored list from the presentArray 
    localStorage.setItem("com.beeminder.dashboard.expandedList", presentArray.join(","));
  }

  // if user selects the "advanced-entry" option from the date drop down,
  // redirect them to the goal page with advanced entry form expanded
  $("select.add-data-date").change(function() {
    var val = $(this).val();
    if (/^advanced-/.test(val)) {
      var user = beemApi.currentUsername;
      var slug = val.replace(/^advanced-/,'');
      var dform = $("form.dashboard-data[data-slug="+slug+"]");

      dform.find("input:submit").prop("disabled",true);
      dform.find("select.add-data-date").val(dform.find("select.add-data-date option:first").val());
      location.href = "/"+beemApi.currentUsername+"/"+slug+"#advanced-entry";
    }
  })
  $(".dashboard-data input").keydown(function(e) {
    if(e.keyCode == 13 && e.metaKey ||
       e.keyCode == 13 && e.ctrlKey) {
      $(this).form().submit();
    }
  });

  $(".dashboard-data").submit(function(event) {
    event.preventDefault();
    var slug = $(this).data("slug");
    $(this).find("input[type='submit']").prop("disabled", true);
    var datapointValue = $(this).find("input[name='datapoint-value']").val();
    if (!datapointValue || datapointValue.length === 0) {
      $("form[data-slug='" + slug + "']").find("input[type='submit']").removeAttr("disabled");
      beetils.showFlash("error", "Please enter a value for this datapoint");
      $(this).find("input.stepper-value").addClass("error");
      return false;
    } else {
      beetils.hideFlash()
      $(this).find("input.stepper-value").removeClass("error");
    }
    var datapointComment = $(this).find("input[name='datapoint-comment']").val();
    var date = $(this).find(".add-data-date").val();
    var urtext = date + " " + datapointValue;
    if (datapointComment && datapointComment.length > 0) {
      urtext += " \"" + datapointComment + "\"";
    }

    beetils.showInfinibee(slug);
    beemApi.addDatapoint(slug, {
        urtext: urtext
      },
      function(data) {
        $("form[data-slug='" + slug + "']").find("input[type='submit']").removeAttr("disabled");
        var goalDiv$ = $(".goal[data-slug='" + slug + "']");
        goalDiv$.find("input[name='datapoint-value']").val("");
        goalDiv$.find("input[name='datapoint-value']").attr("placeholder", "e.g. " + datapointValue);
        goalDiv$.find("input[name='datapoint-value']").focus().blur();
        if (datapointComment && datapointComment.length > 0) {
          goalDiv$.find("input[name='datapoint-comment']").attr("placeholder", datapointComment);
        }
        goalDiv$.find("input[name='datapoint-comment']").focus().blur();
        // the canonical datapoint has been sanitized back in rails, so it 
        // is safe to set this html with the canonical datapoint, even though 
        // it is technically user input text
        goalDiv$.find(".last-datapoint").html(data.canonical);
        goalDiv$.find("input[name='datapoint-comment']").val("");
        goalDiv$.find(".todayta").show();
        refreshGoal(slug);
      },
      function(error) {
        beetils.hideInfinibee(slug);
        $("form[data-slug='" + slug + "']").find("input[type='submit']").removeAttr("disabled");
        beetils.showFlash("error","There was a problem adding your datapoint");
      }
    );
  });

  $("#goals-active .goal .refresh").click(function(event) {
    if ($(this).hasClass("sync")) {
      return true;
    }
    event.preventDefault();
    var slug = $(this).data("slug");
    beetils.showInfinibee(slug);
    $.ajax({
      type: "GET",
      url: "/api/v1/users/me/goals/" + slug + "/refresh_graph.json",
      data: beetils.tokenParams(),
      success: function(data) {
        var goalDiv$ = $(".goal[data-slug='" + slug + "']");
        refreshGoal(slug);
      },
      error: function(error) {
        alert("There was a problem refreshing your goal");
      }
    });
    return false;
  });

  // TODO: DRY THIS UP. in both goal.js and here
  // bind archive check 
  $(".insta-archive-check").click(function(e) {
    e.preventDefault();
    var golarchiving = $(this).data("slug")
    // first check if there's a charge pending
    $.ajax({
      type: "GET",
      url: "/api/v1/users/me/goals/"+$(this).data("slug") + ".json",
      data: beetils.tokenParams(),
      success: function(data) {
        // set the slug data on the insta-archive button to be this goal's 
        $(".insta-archive").data("slug", golarchiving);
        if (data.contract?.pending_amount > 0) {
        // launch the modal explaining the charge and with cancel option
          $("#insta-archive-modal .modal-header .amount").text("$"+data.contract.pending_amount)
          $("#insta-archive-modal").modal("show")
        } else {
          $(".insta-archive").click()
        }
      },
      error: function(error) {
        // should never see this #famouslastwords
        beetils.showFlash("error", "Something went wrong? Contact support@beeminder.com for help");
      }
    })
  });
  // bind archive button
  $(".insta-archive").click(function(e) {
    e.preventDefault();
    if ($(this).hasClass("disabled")) {
      return false;
    } else {
      $(this).addClass("disabled")
    }
    var params = $.extend(beetils.tokenParams(), {
      "_method": "PUT",
      "instaarchive": true
    });
    var __this = $(this)
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + $(this).data("slug") + ".json",
      data: params,
      success: function(data) {
        if (data.contract && data.contract.pending_at) {
          msg = "FYI, this goal still has a $" +
          parseInt(data.contract.pending_amount) +
          " charge pending. Email support@beeminder.com if it's not legit."
          beetils.showFlash("success", msg)
          setTimeout(function() {
            window.location.reload()
          }, 2000)
        } else {
          window.location.reload()
        }
      },
      error: function(error) {
        // should never see this #famouslastwords
        __this.removeClass("disabled")  
        beetils.showFlash("error", "Could not archive this goal &mdash; perhaps it's still in progress?");
      }
    });
  });
  // end bind archive buttons

  $(".dashboard-header-wrapper .sort-button").click(function(e) {
    setSortBy($(this).data("sort-string"));
    sortGoals();
    e.preventDefault();
  });

  $(".dashboard-header-wrapper select").change(function() {
    setSortBy($(this).val());
    sortGoals();
  });

  function updateDoom() {
    $(".goal").each(function(i, e) {
      var _this = $(e)
      var summary = _this.find(".summary")
      var originalText = $.trim(summary.text()).replace(/\s+/g, " ")
      var doom = _this.find(".doom")
      const phat = _this.data("yaw") === -1 && _this.data("dir") === -1;
      doom.text(
        beetils.doomModifier(
          _this.data("slug"), 
          _this.data("limiter") || phat , 
          _this.data("coasting")) + 
        beetils.doomString(
          doom.data("doom"), 
          beetils.doomFormat(_this.data("slug")), 
          doom.data("tz")
        ));
      var text = $.trim(summary.text()).replace(/\s+/g, " ");
      // don't muck with the css if the length is the same
      if (originalText.length === text.length) { return; }
      if (text.length > 60) {
        summary.css("font-size", "12px");
      } else if (text.length > 50) {
        summary.css("font-size", "13px");
      } else if (text.length > 45) {
        summary.css("font-size", "14px");
      } else {
        summary.css("font-size", "16px");
      }
    });
    $(".gallery-goal").each(function(i, e) {
      var _this = $(e)
      var summary = _this.find(".summary")
      var originalText = $.trim(summary.text()).replace(/\s+/g, " ");
      var doom = _this.find(".doom")
      doom.text(
        beetils.doomModifier(
          _this.data("slug"), 
          _this.data("limiter") || _this.data("fatloser"), 
          _this.data("coasting")) + 
        beetils.doomString(
          doom.data("doom"), 
          beetils.doomFormat(_this.data("slug")),
          doom.data("tz")
        ));
      var text = $.trim(summary.text()).replace(/\s+/g, " ");
      // don't muck with the css if the length is the same
      if (originalText.length === text.length) { return; }
      summary.css("font-size", "12px");
    });
  }

  window.setInterval(function() {
    updateDoom();
  }, 1000);

  $(".doom").click(function(e) {
    e.preventDefault();
    if (beetils.doomFormat($(this).data("slug")) === "calendar") {
      beetils.setDoomFormat($(this).data("slug"), "countdown");
    } else {
      beetils.setDoomFormat($(this).data("slug"), "calendar");
    }
    updateDoom();
  });

  $(".goal").each(function(i, e) {
    updateBaremin($(e).data("slug"));
  });

  $(".baremin").click(function(e) {
    e.preventDefault();
    if ($(this).data("kyoom") === true) {
    // don't ever show total for kyoom goals
      beetils.setBareminFormat($(this).data("slug"), "delta");
    } else if (beetils.bareminFormat($(this).data("slug")) === "total") {
      beetils.setBareminFormat($(this).data("slug"), "delta");
    } else {
      beetils.setBareminFormat($(this).data("slug"), "total");
    }
    updateBaremin($(this).data("slug"));
  });

  function filterOnTags(tagstring) {
    tagstring = tagstring.trim();
    var tags = tagstring.toLowerCase().split(",");
    if (tagstring === "") {
      $(".taggings").hide();
    } else if (tags.length === 0) {
      $(".taggings").hide();
    } else {
      $("div[class*='tagging'] .taglist").text(tags.join(", "));
      $(".taggings").show();
    }
    $(".goal").each(function(i, e) {
      if (tagstring.length === 0) { return; }
      var show = true;
      var goalTags = $(e).data("tags").split(',').map(function(e) { return e.toLowerCase(); });
      tags.forEach(function(tag) {
        if (tag.match(/^\!/)) {
          var excludeTag = tag.slice(1, tag.length);
          if (goalTags.indexOf(excludeTag) != -1) {
            show = false;
          }
        }
      });
      if (!show) {
        $(e).hide();
      } else {
        tags.forEach(function(tag) {
          if (!tag.match(/^\!/) && goalTags.indexOf(tag) === -1) {
            show = false;
          }
        });
        if (!show) {
          $(e).hide();
        }
      }
      if (show) { $(e).show(); }
    });
  }
  filterOnTags(location.hash.replace("#", ""));
  window.addEventListener('hashchange', function() {  // HT @zzq
    filterOnTags(location.hash.replace("#", ""));
  });

  function updateGatewayCountdown() {
    let gate = $("#gateway-countdown")
    gate.text(
      `${beetils.doomString(gate.data("gatewayts"), "countdown", "tz")}*`
    )
    gate.parent().removeClass("blue orange red green dkgreen gray").addClass(
      beetils.doomColorClass(gate.data("gatewayts"), gate.data("coasting"))
    );
  }
  window.setInterval(function() {
    updateGatewayCountdown();
  }, 1000);
});
